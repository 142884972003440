<template>
  <v-app class="theme">
    <!-- <router-link to="/pay"> Pay</router-link> -->
    <v-main>
      <Preloader />
      <Menu />
      <!-- <TransitionPage > -->
      <router-view />
      <!-- </TransitionPage> -->
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Preloader from "./components/preloader";
import Menu from "@/components/menu";
import Footer from "@/components/footer";

import TransitionPage from '@/components/TransitionPage.vue';

// import TransitionPage from "@/components/TransitionPage";

export default {
  name: "App",
  components: {
    Preloader,
    Menu,
    Footer,
    TransitionPage
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
body {
  margin: 0px;
  padding: 0px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<style  src="@/main.scss" lang="scss"/>