import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activo: 'en'
  },
  mutations: {
    ActualizarStado(state, len) {
      state.activo = 'es'//len;
      // console.log('State =', state.activo)
      console.log('estado:' + state.activo)
      return state.activo;

    }
  },
  actions: {
    cambiAct(state) {
      state.commit('cambiAct')

    }
  },
  getters: {
    stadocambio: state => {
      return state.activo
    }

  },
  modules: {
  }

})
