import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    customProperties: true,
    themes: {
      light: {
        primary: "#ff5900",
        secondary: "#5170b5",
        accent: "#ff5900",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
      dark: {
        primary: "#ff5900",
        secondary: "#5170b5",
        accent: "#ff5900",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      }
    }
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
});
