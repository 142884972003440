
<template>
  <v-container class="categorias-sections my-12">
    <h1>Categorías</h1>
    <div class="wrap-categorias-main">
      <v-row class="mt-6 wrap-caregorias" style="width: 100%">
        <v-col
          cols="6"
          md="4"
          sm="4"
          v-for="(i, index) in categorias"
          :key="index"
        >
          <div class="btn-categoria">
            <div
              class="bg-color-"
              :style="`background-color:${i.backgroundColor}`"
            ></div>
            <img
              :src="i.imagen"
              height="150px"
              class="img-cate"
              alt=""
              srcset=""
            />
            <span class="clas_span">{{ i.es }}</span>
          </div>
        </v-col>
      </v-row>
      <div class="div"></div>
      <img src="@/assets/ok.png" class="img-hands" alt="" sizes="" srcset="" />
    </div>
  </v-container>
</template>

<script>
import store from "@/store/index.js";
export default {
  name: "Categorias",
  props: {
    lenguaje: {
      type: String,
      defaull: "es",
    },
  },
  data() {
    // this.$i18n.locale = store.state.activo;
    // let st =store.state.activo;
    return {
      // locale: store.state.activo,
      categorias: [
        {
          backgroundColor: "#2D59CD",
          imagen: require("@/assets/categorias/regalos.png"),
          es: "Regalos",
          en: " gifts",
        },
        {
          backgroundColor: "#FFB5D4",
          imagen: require("@/assets/categorias/mascotas.png"),
          es: "Mascotas",
          en: " Pets",
        },
        {
          backgroundColor: "#FFC634",
          imagen: require("@/assets/categorias/resturantes.png"),
          es: "Restaurantes",
          en: " Restaurants",
        },
        {
          backgroundColor: "#97FCCF",
          imagen: require("@/assets/categorias/salud.png"),
          es: "Salud",
          en: " Health",
        },
        {
          backgroundColor: "#C7B5FC",
          imagen: require("@/assets/categorias/postres.png"),
          es: "Postres y Pan",
          en: " Desserts and Bread",
        },
        {
          backgroundColor: "#00cdbc",
          imagen: require("@/assets/categorias/cash.png"),
          es: "Ocho Cash",
          en: " Ocho Cash",
        },
        {
          backgroundColor: "#FCC341",
          imagen: require("@/assets/categorias/cafes.png"),
          es: "Cafes",
          en: " Coffees",
        },
        {
          backgroundColor: "#ADF3F6",
          imagen: require("@/assets/categorias/cuidado-personal.png"),
          es: "Cuidado personal",
          en: " Personal care",
        },
        {
          backgroundColor: "#FDBFB0",
          imagen: require("@/assets/categorias/bebidas.png"),
          es: "Bebidas",
          en: "Drinks",
        },
        {
          backgroundColor: "#FFB5D4",
          imagen: require("@/assets/categorias/electronicos.png"),
          es: "Electronico",
          en: " Electronic",
        },
        {
          backgroundColor: "#97FCCF",
          imagen: require("@/assets/categorias/delivery.png"),
          es: "Mandados",
          en: " Errands",
        },
        {
          backgroundColor: "#FCC341",
          imagen: require("@/assets/categorias/super.png"),
          es: "Super",
          en: " Super",
        },
      ],
    };
  },
  watch: {
    // cambioState(val) {
    //   this.$i18n.locale = val;
    //   this.locale=val;
    // },
  },
  computed: {
    // cambioState(){
    //   return store.getters.stadocambio
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
@import "@/_responsive.scss";

$Naranja: #ff5900;
.categorias-sections {
  h1 {
    color: $Naranja;
    font-size: 40px;
  }
}
.wrap-categorias-main {
  display: flex;
  position: relative;

  .wrap-caregorias {
    width: 70% !important;
  }

  .img-hands {
    position: absolute;
    width: 250px;
    right: 100px;
    transform: translateY(-50px) translateX(50px) rotate(10deg);
    @include responsive(mobile) {
      display: none;
    }
  }
  .div {
    width: 30%;
  }
}
.div {
  @include responsive(mobile) {
    display: none;
  }
}

.btn-categoria {
  height: 90px;
  margin: auto;
  width: 100%;
  border-radius: 10px;
  // background-color: #fff;

  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0px;
  align-items: center;
  @include responsive(mobile) {
    height: 75px;
  }

  .bg-color- {
    position: absolute;
    height: calc(100% - 10px);
    width: calc(100% - 40px);
    right: 5px;
    bottom: 5px;
    opacity: 0.8;
    border-radius: 50px 10px 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.098) 2px 2px 5px;

    @include responsive(mobile) {
      border-radius: 30px 10px 10px 10px;
    }
  }
  .img-cate {
    height: 100%;
    z-index: 3 !important;
  }
  span {
    z-index: 10px !important;
    position: relative;
    margin-right: 20px;
    color: #ffffff;
    @include responsive(mobile) {
      font-size: 12px;
      text-shadow: rgba(0, 0, 0, 0.104) 0px 0px 10px;
    }
    // text-shadow: rgba(0, 0, 0, 0.437) 1px 0px 30px;
    text-align: end;
  }
}
</style>
