import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      dark: true,
      transition:"slide"
    },

  },
  {
    path: '/pay',
    name: 'Pay',
    meta: {
      dark: true,
    },
    component: () => import('../views/pay.vue')
  },
  {
    path: '/cargo',
    name: 'ocho-cargo',
    meta: {
      dark:false,
    },
    component: () => import('../views/cargo.vue')
  },
  {
    path: '/driver',
    name: 'Driver',
    meta: {
      dark:true,
    },
    component: () => import('../views/Driver.vue')
  },
  {
    path: '/terminos-condiciones',
    name: 'Terminos',
    meta: {
      dark:true,
    },
    
    component: () => import('../views/Terminos.vue')
  },
  {
    path: '/terminos-condiciones/promos',
    name: 'TerminosPromo',
    meta: {
      dark:true,
    },
    
    component: () => import('../views/terminosPromos.vue')
  },
  {
    path: '/terminos-condiciones/GiveawayTelamarResort',
    name: 'TelamarResolt',
    meta: {
      dark:true,
    },
    
    component: () => import('../views/TerminosTelamar.vue')
  },
  {
    path: '/politicas',
    name: 'PrivacyPolicy',
    meta: {
      dark:true,
      menuColor: "ligth",
    },
    
    component: () => import('../views/politicas.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
