<template>
  <div class="home-sections flex-center">
    <svg
      class="svg-"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 220 247"
      style="enable-background: new 0 0 220 247"
      xml:space="preserve"
    >
      <circle class="st0" cx="109.5" cy="123.5" r="75" />
    </svg>
    <svg
      class="svg-2"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 220 247"
      style="enable-background: new 0 0 220 247"
      xml:space="preserve"
    >
      <circle class="st0" cx="109.5" cy="123.5" r="75" />
    </svg>
    <img
      src="@/assets/categorias/resturantes.png"
      class="hambu flotando-reverse"
      alt=""
      srcset=""
    />
    <img
      src="@/assets/categorias/electronicos.png"
      class="electro flotando"
      alt=""
      srcset=""
    />
    <img
      src="@/assets/categorias/cash.png"
      class="cash flotando-reverse"
      alt=""
      srcset=""
    />
    <img
      src="@/assets/categorias/mascotas.png"
      class="mascotas flotando"
      alt=""
      srcset=""
    />
    <img
      src="@/assets/categorias/regalos.png"
      class="regalos flotando-reverse"
      alt=""
      srcset=""
    />
    <v-container class="flex-center" style="flex-direction: column">
      <div class="lottie flex-center mb-4">
        <lottie :options="defaultOptions" />
      </div>
      <h3 style="color: #fff" class="">
        Disfruta en minutos los productos que deseas de los comercios que amas.
      </h3>
    </v-container>
    <div class="wrap-carr">
      <div class="wrap-car">
        <img src="@/assets/phone2/4.jpeg" alt="" class="item uno" srcset="" />
        <img
          src="@/assets/phone2/Principal2.png"
          alt=""
          class="card-item"
          srcset=""
        />
        <img src="@/assets/phone2/3.jpeg" alt="" class="item dos" srcset="" />
      </div>
    </div>

    <div class="redes-social">
      <a target="_blank" href="https://www.facebook.com/ochoapp/" style="">
        <v-icon color="#fff">mdi-facebook</v-icon>
      </a>
      <a target="_blank" href="https://www.instagram.com/ochoapp.hn/">
        <v-icon color="#fff">mdi-instagram</v-icon>
      </a>
      <a target="_blank" href="https://www.linkedin.com/company/ochoapp">
        <v-icon color="#fff">mdi-linkedin</v-icon></a
      >
    </div>
  </div>
</template>

<script>
import Lottie from "./Lottie";

import store from "@/store/index.js";
import * as animationData from "@/assets/lottie/laManera.json";
export default {
  name: "Homee",
  components: {
    Lottie,
  },
  props: {},
  data() {
    return {
      animationSpeed: 2,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
        lenguaje: "",
      },
      imgs: [
        require("@/assets/capt/1.jpg"),
        require("@/assets/capt/2.jpg"),
        require("@/assets/capt/3.jpg"),
        require("@/assets/capt/4.jpg"),
        require("@/assets/capt/5.jpg"),
        require("@/assets/capt/6.jpg"),
        require("@/assets/capt/7.jpg"),
        require("@/assets/capt/8.jpg"),
        require("@/assets/capt/9.jpg"),
      ],
    };
  },
  mounted() {},
};
</script>




<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
@import "@/_responsive.scss";


html[data-scroll="0"] {
  .transf_Y {
    transform: translateY(0px);
  }
}
.transf_Y {
  transform: translateY(0px);
  transition: 0.5s ease-in-out;
}


.svg- {
  position: absolute;
  width: 300px;
  left: -150px;
  bottom: 10px;
  @include responsive(mobile) {
    display: none;
  }
}
.svg-2 {
  position: absolute;
  width: 300px;
  right: -150px;
  top: 10px;
  @include responsive(mobile) {
    display: none;
  }
}
.st0 {
  fill: none;
  stroke: #ff7226;
  stroke-width: 63;
  stroke-miterlimit: 10;
}

.home-sections {
  width: 100%;
  min-height: 100vh;
  max-height: 900px;
  background-color: #ff5900;
  padding-bottom: 10%;

  background-image: radial-gradient(
    circle at 50% 10%,
    #ff5900,
    #ff6d1f
  ) !important;
  margin-bottom: 300px;
  @include responsive(mobile) {
    text-align: center;
    padding-bottom: 40%;
  }
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
}
.home-sections {
  flex-direction: column;
  position: relative;
}
.lottie {
  width: 50%;
  height: auto;

  @include responsive(mobile) {
    width: 100% !important;
  }
}
.transf_Y {
  transform: translateY(0px);
  transition: 0.5s ease-in-out;
}
html[data-scroll="0"] {
  .wrap-carr {
    width: 70%;
    height: 600px;
    position: absolute;
    padding: 30px;
    bottom: -300px;
    display: flex;
    transition: 0.5s ease-in;
    flex-wrap: nowrap;
    border-radius: 20px;
    align-items: center;
    justify-content: space-between;

    .wrap-car {
      .uno {
        transform: translateX(0px);
        z-index: 1 !important;
      }
      .dos {
        transform: translateX(0px);
        z-index: 1 !important;
      }
      .card-item {
        transform: translateY(-50px);
        @include responsive(mobile) {
          transform: translateY(0px);
        }
      }
    }
  }
}
.wrap-carr {
  width: 70%;
  height: 600px;
  position: absolute;
  padding: 30px;
  bottom: -300px;
  display: flex;
  transition: 0.5s ease-in;
  flex-wrap: nowrap;
  border-radius: 20px;
  align-items: center;
  justify-content: space-between;

  .wrap-car {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      position: absolute;
      width: 190px;
      border-radius: 10px;
      margin-top: 20px;
      box-shadow: rgba(0, 0, 0, 0.144) 0px 10px 20px;
      transition: 1s ease-in-out;
      @include responsive(desktop) {
        width: 170px;
      }
    }
    .uno {
      transform: translateX(-300px);
      z-index: 1 !important;
      @include responsive(mobile) {
        transform: translateX(0px);
        display: none;
      }
    }
    .dos {
      transform: translateX(300px);
      z-index: 1 !important;
      @include responsive(mobile) {
        transform: translateX(0px);
        display: none;
      }
    }
    .card-item {
      transition: 1s ease-in-out;
      width: 320px;
      height: auto;
  
      margin-top: 0px;
      border-radius: 10px !important;
      z-index: 2;
      @include responsive(desktop) {
        width: 300px !important;
      }

      // box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
      // will-change: transform;
      // transform: translate3d(-0%, 0px, 100px) scale3d(1, 1, 1) rotateX(0deg)
      //   rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      // transform-style: preserve-3d;
    }
  }
}
.hambu {
  width: 100px;
  height: 100px;
  position: absolute;
  right: 100px;
  top: 150px;
  @include responsive(mobile) {
    width: 100px;
    height: 100px;
    right: 10px;
    top: 100px;
  }
}
.electro {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 100px;
  @include responsive(mobile) {
    width: 100px;
    height: 100px;

    top: 0vh;
  }
}
.cash {
  width: 110px;
  height: 110px;
  position: absolute;
  left: 100px;
  top: 200px;
  @include responsive(mobile) {
    width: 100px;
    height: 100px;
    left: 0px;
    top: 10vh;
  }
}
.mascotas {
  width: 110px;
  height: 110px;
  position: absolute;
  right: 200px;
  top: 400px;
  @include responsive(mobile) {
    width: 100px;
    height: 100px;
    right: 0px;
    top: 80vh;
  }
}
.regalos {
  width: 110px;
  height: 110px;
  position: absolute;
  left: 200px;
  top: 400px;
  @include responsive(mobile) {
    width: 100px;
    height: 100px;
    left: 10px;
    top: 60vh;
  }
}
.flotando {
  -webkit-animation: bounce-down 2s linear infinite;
  animation: bounce-down 2s linear infinite;
}
.flotando-reverse {
  -webkit-animation: bounce-down 2s alternate-reverse infinite;
  animation: bounce-down 2s alternate-reverse infinite;
}
@-webkit-keyframes bounce-down {
  25% {
    -webkit-transform: translateY(-4px);
  }
  50%,
  100% {
    -webkit-transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(4px);
  }
}

@keyframes bounce-down {
  25% {
    transform: translateY(-4px);
  }
  50%,
  100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(4px);
  }
}

.section-1-svg path {
  stroke-dasharray: 1;
  stroke-dashoffset: 2;
  animation: lineanimation 1s cubic-bezier(0.4, 0, 0.2, 1) alternate;
}
.redes-social {
  width: 20px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 50px;
  bottom: 50px;
  a {
    text-decoration: none;
    transition: 0.3s ease-in-out;
    &:hover {
      transform: scale(1.5, 1.5);
    }
  }

  @include responsive(mobile) {
    
      position: relative !important;

      height: auto;
      justify-content: space-between;
 
      left: 0px; 
     bottom: 0px; 
      flex-direction: row;
      width: 150px;
 
  }
}
</style>
