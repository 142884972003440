


<template>
  <!-- <img src="@/assets/I_love_you.png" class="hand-ly" />
  
<div class="wrapp-hand-footer"></div> -->
  <div id="footer" class="Footer">
    <v-container>
      <v-row>
        <v-col cols="12" sm="4" md="4" lg="4">
          <div class="logo-redes">
            <img src="@/assets/logo-blanco.svg" alt="" />
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="wrapp" cols="12" sm="6" md="6" lg="6">
          <div class="terminos-redes">
            <div class="redes">
              <p class="mb-0">Siguenos en:</p>
              <div class="iconos-redes text-caption">
                <a
                  target="_blank"
                  href="https://www.facebook.com/ochoapp/"
                  style=""
                >
                  <v-icon color="#fff">mdi-facebook</v-icon>
                </a>
                <a target="_blank" href="https://www.instagram.com/ochoapp.hn/">
                  <v-icon color="#fff">mdi-instagram</v-icon>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/ochoapp"
                >
                  <v-icon color="#fff">mdi-linkedin</v-icon></a
                >
              </div>
            </div>
          </div>
          <div class="terminos-redes">
            <div class="links">
              <router-link :to="{ path: '/terminos-condiciones' }"
                >Términos y condiciones</router-link
              >
            </div>
            <div class="links">
              <router-link :to="{ path: '/politicas' }">
                Políticas de Privacidad</router-link
              >
            </div>
            <div class="links">
              <router-link :to="{ path: '/terminos-condiciones/promos' }">
                Promociones</router-link
              >
            </div>
            <div class="links">
              <router-link :to="{ path: '/terminos-condiciones/GiveawayTelamarResort' }">
                Giveaway Telamar Resort</router-link
              >
            </div>
          </div>
          <div class="CTA">
            <div class="sistema">
              Disponible en: <v-icon color="#fff">mdi-apple</v-icon>
              <v-icon color="#fff">mdi-android</v-icon>
            </div>
            <!-- <button
        class="btn btn-dark"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Descarga la App
      </button> -->
            <descarga className="btn btn-dark">
              <img
                src="@/assets/pulpito.svg"
                slot="pulpito"
                class="mr-2"
                width="30px"
                alt=""
              />
            </descarga>
          </div>
        </v-col>
      </v-row>

      <!-- <div class="copyright">
							© 2021 OchoApp						</div> -->
    </v-container>
  </div>
</template>

<script>
import store from "@/store/index.js";
import descarga from "./btnDescarga";
export default {
  name: "Footer",
  components: {
    descarga,
  },
  data() {
    // this.$i18n.locale = store.state.activo;
    return {
      // idio: store.state.activo,
    };
  },
  watch: {
    // cambioState(val) {
    //   this.$i18n.locale = val;
    //   this.idio = val;
    // },
  },
  methods: {
    alert_d() {
      alert("djhndjhdf");
    },
  },
  computed: {
    cambioState() {
      return store.getters.stadocambio;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
$Naranja: #ff5900;

@import "@/_responsive.scss";

.Footer {
  width: 100%;
  height: 200px;

  background-color: $Naranja;
  display: flex;

  background-image: url("../assets/icon-logo.svg");
  background-position: center 20px;
  background-size: 300px;
  background-repeat: no-repeat;
  justify-content: space-between;
  z-index: 50 !important;
  overflow: hidden;
  align-items: center;
  padding-top: 20px;
  border-bottom: #242424 20px solid;
  @include responsive(mobile) {
    flex-direction: column;
    background-position: 50% 10px;
    height: auto;
    padding-bottom: 20px;
    justify-content: center;
  }
  .logo-redes {
    width: 300px;
    height: auto;
    // background-color: rgba(159, 22, 22, 0.208);
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      width: 100%;
      height: 100%;
    }
    @include responsive(desktop) {
      width: 200px !important;
      margin-left: 0px;
      justify-content: center;

      img {
        width: 100px;
      }
    }
    @include responsive(mobile) {
      width: 100% !important;
      margin-left: 0px;
      justify-content: center;

      img {
        width: 100px;
      }
    }
    img {
      width: 150px;
    }

    .redes {
      display: flex;
      color: white;

      align-items: center;
    }
  }
}
.wrapp {
  display: flex;
  justify-content: flex-end;

  @include responsive(mobile) {
    display: block;
    margin-top: 10px;
  }
}
.terminos-redes {
  @include responsive(mobile) {
    // display: none;
  }

  color: white;
  width: 200px;

  .links {
    a {
      text-decoration: none;
      color: white;
      font-size: 13px;
      font-weight: 300;
      &:hover {
        opacity: 0.5;
      }
    }
    @include responsive(desktop) {
      width: 200px;
      margin-right: 20px;
    }

    @include responsive(mobile) {
      text-align: center;
    }

    // @include responsive(mobile) {
    //   padding: 5px;
    // }
  }
  .redes {
    @include responsive(mobile) {
      margin-top: 10px;
      text-align: center;
    }

    width: 100%;
    .iconos-redes {
      font-weight: 100 !important;
      @include responsive(mobile) {
        justify-content: space-around;
        align-items: center;
        display: flex;
        width: 100%;
      }
      width: 45%;
      display: flex;
      font-size: 25px;
      justify-content: space-between;
      a {
        text-decoration: none;
        color: white;
        transition: 0.3s ease-in-out;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}
.wrapp {
  @include responsive(mobile) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }
}
.CTA {
  display: flex;

  flex-direction: column;
  align-items: flex-end;
  align-items: flex-start;

  @include responsive(mobile) {
    margin-right: 0px;
    margin-top: 20px;
  }
  @include responsive(desktop) {
    margin-right: 20px;
    margin-top: 20px;
  }
  .sistema {
    display: flex;
    color: white;
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include responsive(mobile) {
      display: none;
    }
    i {
      margin-left: 5px;
    }
  }
}
</style>
