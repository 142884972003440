<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 152.7 69.4"
    style="enable-background: new 0 0 152.7 69.4"
    xml:space="preserve"
  >
    <path
      :class="{ st0: dark, 'st0-dark': dark == false }"
      d="M0,31.9c0-11.4,9.2-20.7,20.5-20.7c11.4,0,20.7,9.2,20.7,20.5c0,11.4-9.2,20.7-20.5,20.7h-0.1
	C9.3,52.5,0,43.3,0,31.9 M28.2,31.9c0-4.2-3.4-7.7-7.7-7.7c-4.2,0-7.7,3.4-7.7,7.7c0,4.2,3.4,7.7,7.7,7.7
	C24.8,39.6,28.2,36.2,28.2,31.9C28.2,31.9,28.2,31.9,28.2,31.9z"
    />
    <path
      :class="{ st0: dark, 'st0-dark': dark == false }"
      d="M39,31.9C39,20.4,48.2,11.1,59.6,11.1c5,0,9.9,1.8,13.7,5.1c0.6,0.5,0.7,1.4,0.2,2c0,0,0,0-0.1,0.1l-7.2,7.4
	c-0.5,0.5-1.2,0.6-1.8,0.1c-3.4-2.6-8.2-1.9-10.8,1.5c-1,1.3-1.5,2.9-1.5,4.6c0,4.3,3.4,7.7,7.7,7.8c1.6,0.1,3.2-0.5,4.4-1.5
	c0.5-0.4,1.3-0.4,1.8,0.1l7.3,7.2c0.5,0.5,0.5,1.4,0,2c0,0-0.1,0.1-0.1,0.1c-3.7,3.3-8.6,5.1-13.5,5C48.3,52.6,39.1,43.4,39,32v0
	C39,32,39,31.9,39,31.9"
    />
    <path
      :class="{ st0: dark, 'st0-dark': dark == false }"
      d="M113.5,33.3c0-1.7-0.2-3.5-0.5-5.2c-2.1-10.6-10.4-17-20.1-17c-1.8,0-3.7,0.3-5.4,0.9c-0.7,0.3-1.5-0.1-1.8-0.8
	c-0.1-0.2-0.1-0.3-0.1-0.5V1.4C85.7,0.6,85,0,84.3,0h-11c-0.8,0-1.4,0.6-1.4,1.4c0,0,0,0,0,0v49.7c0,0.8,0.6,1.4,1.4,1.4l0,0h11
	c0.8,0,1.4-0.6,1.4-1.4l0,0V32c0-4.6,3.1-7.5,7.2-7.5c4.7,0,7.6,3.4,7.6,8.5v18.2c0,0.8,0.6,1.4,1.4,1.4l0,0h10.2
	c0.8,0,1.4-0.6,1.4-1.4l0,0L113.5,33.3z"
    />
    <path
      :class="{ st0: dark, 'st0-dark': dark == false }"
      d="M120.9,49.1c3.4,2.3,7.5,3.5,11.6,3.5l-0.1,0c1.7,0,3.4-0.2,5-0.6c0.2,0,0.3-0.1,0.5-0.1c2.7-0.7,5.3-2,7.5-3.8
	c2.5-2,4.4-4.6,5.7-7.5c2-4.7,2.1-10.1,0.2-14.9c-0.5-1.3-1.2-2.4-2-3.5c0,0,0,0,0,0c-1.4-1.8-3.2-3.3-5.4-4.2
	c-0.1-0.1-0.2-0.1-0.4-0.1c-0.7-0.3-1.4-0.5-2.2-0.6c-0.9-0.2-1.9-0.2-2.9-0.2h0c-0.2,0-0.5,0-0.7,0.1c-3.4,0.3-6.6,1.9-8.7,4.6
	c-0.3,0.5-0.3,1.1,0,1.6c0.3,0.4,0.8,0.6,1.3,0.5c2.9-0.8,6,0.1,8,2.3c3,3.3,2.8,8.3-0.5,11.3c-1.4,1.3-3.2,2-5.1,2.1h-0.7
	c-1.9-0.1-3.7-0.9-5-2.2c-4.2-4-4.4-10.9-2-15.9c0.2-0.4,0.4-0.9,0.7-1.3c1.7-2.6,4.2-4.7,7.2-5.8c0,0,0.8-0.3,1.4-0.4
	c0.6-0.1,1-0.7,0.9-1.3c0-0.7-0.5-1.2-1.2-1.3c-0.6-0.1-1.3-0.1-1.9-0.1c-11.4,0-20.6,9.1-20.8,20.5
	C111.5,38.7,115.1,45.2,120.9,49.1 M136.3,22.5c-1.7,0.6-3.3,0.2-3.7-1c0,0,0,0,0-0.1c-0.3-1.1,0.8-2.4,2.4-2.9
	c1.6-0.5,3.1-0.2,3.6,0.8c0,0.1,0.1,0.1,0.1,0.2C139.1,20.6,138,21.9,136.3,22.5 M141.8,22c-0.4,0.1-0.8-0.1-0.8-0.4
	c0-0.1,0-0.1,0-0.2c0.1-0.2,0.4-0.4,0.6-0.4c0.2,0,0.5,0,0.7,0.2c0.1,0.1,0.1,0.1,0.1,0.2C142.4,21.7,142.2,22,141.8,22 M139.4,19.4
	C139.4,19.3,139.4,19.3,139.4,19.4c0-0.7,0.7-1.1,1.6-1.1c0.1,0,0.1,0,0.2,0c0.6,0,1.1,0.2,1.5,0.7c0.1,0.2,0.2,0.4,0.2,0.6
	c-0.1,0.6-0.8,1-1.8,1C140.2,20.4,139.5,19.9,139.4,19.4"
    />
    <path
      :class="{ ModeColor: dark }"
      d="M6.4,57.8v10.7h6.5v0.8H5.5V57.8H6.4z"
    />
    <path
      :class="{ ModeColor: dark }"
      d="M23.7,69.3l-1.3-2.8h-6.9l-1.3,2.8h-1l5.2-11.5h1l5.2,11.5H23.7z M22.1,65.6l-3.1-7l-3.1,7H22.1z"
    />
    <path
      :class="{ ModeColor: dark }"
      d="M40.6,59.1l-4.4,6.8h-0.3l-4.4-6.8v10.3h-0.9V57.8h1.1l4.4,6.7l4.4-6.7h1.1v11.5h-0.9L40.6,59.1z"
    />
    <path
      :class="{ ModeColor: dark }"
      d="M53.2,69.3l-1.3-2.8h-6.9l-1.3,2.8h-1L48,57.8h1l5.2,11.5H53.2z M51.6,65.6l-3.1-7l-3.1,7H51.6z"
    />
    <path
      :class="{ ModeColor: dark }"
      d="M56,57.8l7.8,9.5v-9.5h0.9v11.5h-0.3l-7.8-9.5v9.5h-0.9V57.8H56z"
    />
    <path
      :class="{ ModeColor: dark }"
      d="M68.1,63.1h6.8v0.8h-6.8v4.5h7.3v0.9h-8.2V57.8h8v0.8h-7.1V63.1z"
    />
    <path
      :class="{ ModeColor: dark }"
      d="M86.6,69.3h-1.2l-4-4.4h-3.1v4.4h-0.9V57.8h5.1c2-0.1,3.7,1.4,3.8,3.3c0,0.1,0,0.2,0,0.3
	c0.1,1.9-1.4,3.5-3.3,3.5c-0.2,0-0.3,0-0.5,0L86.6,69.3z M78.3,64.1h4.1c1.4,0.2,2.7-0.8,2.9-2.2c0-0.2,0-0.4,0-0.5
	c0.1-1.5-1.1-2.7-2.6-2.8c-0.1,0-0.2,0-0.3,0h-4.2V64.1z"
    />
    <path
      :class="{ ModeColor: dark }"
      d="M97.3,69.3L96,66.5h-6.9l-1.3,2.8h-1l5.2-11.5h1l5.2,11.5H97.3z M95.7,65.6l-3.1-7l-3.1,7H95.7z"
    />
    <path
      :class="{ ModeColor: dark }"
      d="M105,63.3h6.6v0.8H105v5.2h-0.9V57.8h7.8v0.9H105V63.3z"
    />
    <path
      :class="{ ModeColor: dark }"
      d="M123,69.3l-1.3-2.8h-6.9l-1.3,2.8h-1l5.2-11.5h1l5.2,11.5H123z M121.4,65.6l-3.1-7l-3.1,7H121.4z M118.5,56.6
	l1.5-2v0H119l-1.2,2v0.1L118.5,56.6z"
    />
    <path
      :class="{ ModeColor: dark }"
      d="M134.3,67.7c-1.1,1.1-2.6,1.7-4.2,1.7c-3.3,0-5.9-2.6-5.9-5.9c0-3.3,2.6-5.9,5.9-5.9c0,0,0,0,0,0
	c1.6,0,3.1,0.6,4.2,1.7l-0.6,0.5c-1-0.9-2.2-1.5-3.6-1.5c-2.8,0-5.1,2.3-5.1,5.1c0,2.8,2.3,5.1,5.1,5.1c1.3,0,2.6-0.5,3.6-1.5
	L134.3,67.7z"
    />
    <rect
      x="136.4"
      y="57.8"
      :class="{ ModeColor: dark }"
      width="0.9"
      height="11.5"
    />
    <path
      :class="{ ModeColor: dark }"
      d="M140.7,57.8v10.7h6.5v0.8h-7.4V57.8H140.7z"
    />
  </svg>
</template>

<script>
export default {
  name: "Logo",
  props: ["dark"],
  data() {
    return {
     
    };
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
html[data-scroll="0"] {
  .st0 {
    fill: #fff;
    //   fill: #ff5900;
  }
  .ModeColor {
    fill: #ffffff;
  }
  .st0-dark {
    fill: #ff5900 !important;
  }
  .dark {
    fill: #ffffff !important;
  }
}
  .st0-dark {
    fill: #ff5900 !important;
  }
.st0 {
  //   fill: #fff;
  fill: #ff5900;
}
.ModeColor {
  fill: #4d4c4e;
}
</style>
