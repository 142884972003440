

<template>
  <div class="nav-bar-top" style="z-index: 110">
    <transition name="slide-right" mode="out-in">
      <div class="menu-full" v-if="menuOpen">
        <ul class="mb-12 text-center">
         
          <li @click="menuOpen = !menuOpen" class="text-center mt-2">
            <router-link :to="{ path: '/pay', name: 'Pay' }"
              >Ocho Pay</router-link
            >
          </li>
          <li @click="menuOpen = !menuOpen" class="text-center mt-2">
            <router-link :to="{ path: '/cargo', name: 'ocho-cargo' }"
              >Ocho Cargo</router-link
            >
          </li>
          <li @click="menuOpen = !menuOpen">
            <a href="https://ocho-join-us.web.app/joinus" target="_blank"> Registra tu Comercio</a >
          </li>
          <li @click="menuOpen = !menuOpen">
            <router-link
              :to="{ path: '/driver', name: 'Driver' }">
              Conviértete en Chavo
            </router-link>
          </li>
        </ul>
        <descarga className="btn-dark">
          <img
            src="@/assets/pulpito.svg"
            slot="pulpito"
            class="mr-2"
            width="30px"
            alt=""
          />
        </descarga>
      </div>
    </transition>
    <!-- <img src="@/assets/logo.svg" class="logo-nav"  alt="" /> -->
    <v-container class="flex">
      <div class="logo-nav mr-3" @click="send_home" style="z-index: 100">
        <Logo :dark="color_dark" />
      </div>
      <v-btn
        elevation="0"
        icon
        @click="menuOpen = !menuOpen"
        style="z-index: 500"
        absolute
        right
        outlined
        class="menu-open"
        :color="menuOpen ? '#fff' : '#ff5900'"
      >
        <v-icon>
          {{ menuOpen ? "mdi-close" : "mdi-menu" }}
        </v-icon>
      </v-btn>

      <div  :class="{'menu-list':color_dark,'menu-list-dark':!color_dark,links:true}">
        <!-- <router-link :to="{ path: '/', name: 'Home' }"> Home</router-link> -->

        <router-link :to="{ path: '/pay', name: 'Pay' }">Ocho Pay</router-link>
        <router-link :to="{ path: '/cargo', name: 'ocho-cargo' }"
          >Ocho Cargo</router-link
        >
        <a href="https://ocho-join-us.web.app/joinus" target="_blank"> Registra tu Comercio</a >
        <router-link :to="{ path: '/driver', name: 'Driver' }">Conviértete en Chavo</router-link>

        <descarga className="btn-dark">
          <img
            src="@/assets/pulpito.svg"
            slot="pulpito"
            class="mr-2"
            width="30px"
            alt=""
          />
        </descarga>
      </div>
    </v-container>
  </div>
</template>


<script>
import Logo from "./logo";
import descarga from "./btnDescarga";
// import lenguaje from "@/idioma.json";.

// import store from "@/store/index.js";
export default {
  name: "Menu",
  props: {},
  components: {
    descarga,
    Logo,
  },
  data() {
    // this.$i18n.locale = store.state.activo;
    return {
      // lengua: store.state.activo,
      color_dark: null,
      menuOpen: false,
      colormenu: "ligth",
      linksmode: "links",
    };
  },
  watch: {
    $route(to, from) {
      console.log(to.meta.dark);
      if (to.meta.dark) {
        this.color_dark = true;
      } else {
        this.color_dark = false;
      }
      // this.nameIdC = this.ruta;
      // if (to.name == "Home" ||to.name == "tracking") {
      //   this.flag = false;
      // } else {
      //   this.flag = true;
      // }
    },
  },

  mounted() {
    this.color_dark = this.$route.meta.dark;
  },
  methods: {
    send_home() {
      this.$router.push("/");
      this.menuOpen = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/_responsive.scss";
$Naranja: #ff5900;


html[data-scroll="0"] {
  .menu-list {
    display: block;
    font-family: "poppins-regular";
    @include responsive(mobile) {
      display: none !important;
    }
    a {
      color: #fff;
    }
  }

  .nav-bar-top {
    @include responsive(mobile) {
      backdrop-filter: saturate(180%) blur(20px);
      background-color: rgba(255, 255, 255, 0.72);
      width: 100%;
      height: 70px;
      border-bottom: #f5f5f7 1px solid;
      transition: 0.3s ease-in-out;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 99;
      position: fixed;
      @include responsive(mobile) {
        border-bottom: none !important;
        backdrop-filter: none !important;
        background-color: transparent !important;
      }
    }
    .logo-nav {
      width: 150px;
      cursor: pointer;
      @include responsive(mobile) {
        width: 100px;
      }
    }
    height: 120px;
    border-bottom: #f5f5f700 1px solid;
    backdrop-filter: none;
    background-color: rgba(0, 0, 0, 0);

    .Dark {
      a {
        color: white !important;
        text-shadow: rgba(0, 0, 0, 0.365) 0px 3px 10px;
      }
    }
  }
}

  .menu-list-dark {
    display: block;
    font-family: "poppins-regular";
    @include responsive(mobile) {
      display: none !important;
    }
    a {
      color:#202E4F ;
    }
  }
html[data-scroll="0"] {
  .router-link-active {
    color: #fff;
  }
  .menu-open {
    @include responsive(mobile) {
      background-color: rgba(255, 255, 255, 0.415);
    }
  }
}
a {
  color: #fff;
}
.router-link-exact-active {
  color: #ff5900 !important;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 15px;
  border: #ff5900 1px solid;
}
.router-link-active {
  color: #ff5900;
}
.menu-open {
  display: none;
  transition: 0.3s ease-in-out;
  @include responsive(mobile) {
    display: block !important;
  }
}
.logo-nav {
  transition: 0.3s ease-in-out;
  width: 110px;

  @include responsive(mobile) {
    margin-left: 0px !important;
    margin-right: 0px !important;

    a {
      width: 100px;
    }
    width: 100px;
  }
  @include responsive(desktop) {
    margin-left: 30px;
    a {
      width: 100px;
    }
    width: 100px;
  }
  @include responsive(small) {
    margin-left: 20px;
    a {
      width: 100px;
    }
    width: 100px;
  }
}
.nav-bar-top {
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255, 255, 255, 0.72);
  width: 100%;
  height: 70px;

  border-bottom: #f5f5f7 1px solid;
  transition: 0.3s ease-in-out;

  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  position: fixed;

  .links {
    font-family: "poppins-regular";

    display: flex;
    // justify-content: space-between;
    justify-content: flex-end;
    align-items: center;
    font-family: sans-serif;
    text-decoration: none;

    @include responsive(desktop) {
      margin-right: 20px;
      width: auto;
    }
    @include responsive(mobile) {
      margin-right: 0px;
      width: auto;
    }
    @include responsive(small) {
      margin-right: 20px;
      width: auto;
    }
  }
}
.menu-list {
  display: block;
  font-family: "poppins-regular";
  @include responsive(mobile) {
    display: none !important;
  }
  a {
    color: #ff5900;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-menu {
  ul {
    list-style-type: none;
  }
}
.menu-full {
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0px;
  z-index: 99;
  top: 0px;
  background-image: radial-gradient(
    circle at 100% 100%,
    #ff5900,
    #ff823f
  ) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include responsive(mobile) {
    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      li {
        font-size: 30px;
        color: #ffffff !important;
        a {
          color: #ffffff;
        }
      }
    }
  }
}
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  transform: translate(100vw, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  transform: translate(100vw, 0);
}

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}
a {
  font-family: "poppins-regular";
  text-decoration: none;

  margin-right: 20px;
}
</style>
