


<template >
  <section class="wrap-home-main">
    <Homee />

    <v-container class="mb-12">
      <v-row class="flex-center">
         <v-col cols="12" md="6" sm="6" lg="6">
          <img src="@/assets/delivery.png" width="100%" alt="" srcset="" />
        </v-col>
        <v-col cols="12" md="6" sm="6" lg="6">
          <h1 class="titulo-ocho revelar text-center-mobile" style="z-index: 2">
            <span class="z-10" style="color: #202e4f"
              >Somos el control remoto de tu
            </span>
            <span style="color: #ff5900; position: relative; z-index: 1"
              >ciudad
              <div class="section-1-svg g-show-on-scroll w-embed">
                <svg
                  viewBox="0 0 290 147"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M248.38 4C45.3799 21.5 -21.1201 56 13.8799 106.5C48.8799 157 194.38 149.5 262.38 111C330.38 72.5 238.88 25.7173 143.38 33"
                    stroke="#FFC634"
                    stroke-width="5"
                    stroke-linecap="round"
                    pathLength="1"
                  ></path>
                </svg></div
            ></span>
          </h1>
          <p class="mt-4 revelar z-10 text-center-mobile">
            Descarga ocho y disfruta al instante de tus restaurantes favoritos
            bebidas, medicinas, envíos, supermercados y mucho más.
          </p>
          <div class="div revelar text-center-mobile">
            <descarga className="v-btn btn-download btn-primary" />
          </div>
        </v-col>
       
      </v-row>
    </v-container>

    <Categorias />
    <v-container class="productoss">
      <h1 class="text-center">Productos</h1>
      <v-row class="mt-6">
        <v-col
          v-for="(i, index) in products"
          :key="index"
          class="flex-center"
          lg="4"
        >
          <div class="wrap-product" @click="redirecto(i.redirect, i.router)">
            <div class="wra-img">
              <img :src="i.logo" style="z-index: 2" height="50px" alt="" />
              <img :src="i.back" class="back" alt="" />
            </div>

            <h3>
              La manera <span class="facil-style">fácil</span> de {{ i.text }}
            </h3>
         
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Categorias from "@/components/Categorias";
import Homee from "@/components/Home";
import Phone from "@/components/phone";
import ScrollReveal from "scrollreveal";
import descarga from "@/components/btnDescarga";
import store from "@/store/index.js";

export default {
  name: "Home",
  components: {
    Categorias,
    descarga,
    Phone,
    Homee,
  },
  data() {
    // this.$i18n.locale = store.state.activo;
    return {
      pays: [
        require("@/assets/icon/dilo.png"),
        require("@/assets/icon/card-3d.png"),
        require("@/assets/icon/pay-ocho.svg"),
        require("@/assets/icon/cash.png"),
        require("@/assets/icon/tigoMoney.png"),
        // require("@/assets/icon/tengo.png"),
        // require("@/assets/icon/mastercard.png"),
      ],
      // leng: store.state.activo,
      imghome: screen.width >= 500 ? true : false,
      linkdes: screen.width <= 800 ? false : true,
      products: [
        {
          logo: require("@/assets/productos/pay.svg"),
          back: require("@/assets/productos/O.svg"),
          redirect: "/pay",
          text: "cobrar",
          router: true,
        },
        {
          logo: require("@/assets/productos/cargo.svg"),
          back: require("@/assets/productos/O.svg"),
          redirect: "/cargo",
          text: "enviar tus productos",
          router: true,
        },
        {
          logo: require("@/assets/productos/menu.svg"),
          back: require("@/assets/productos/O.svg"),
          redirect: "https://menu.hn/",
          text: "ordenar",
          router: false,
        },
      ],
    };
  },
  computed: {
    // cambioState() {
    //   return store.getters.stadocambio;
    // },
  },
  watch: {
    // cambioState(val) {
    //   this.$i18n.leng = val;
    //   this.$i18n.locale = val;
    //   this.leng = val;
    // },
  },
  mounted: function () {
    window.scrollTo(0, 0);
    ScrollReveal({
      easing: "ease-in-out",
      reset: true,
      delay: 100,
      distance: "50px",
      origin: "bottom",
    }).reveal(".revelar");
  },
  methods: {
    redirecto(url, value) {
      console.debug(value, url);
      if (value == true) {
        this.$router.push({ path: url });
      } else {
        // window.location = `${url}`;
        var win = window.open(url, "_blank");
        // Cambiar el foco al nuevo tab (punto opcional)
        win.focus();
      }
    },
    descargar: function () {
      if (navigator.platform === "iPhone") {
        window.location.href =
          "https://apps.apple.com/hn/app/ocho/id1471212294";
      } else {
        window.location.href =
          "https://play.google.com/store/apps/details?id=io.beanar.ocho&hl=es_419&gl=US";
      }
    },
  },
};
</script>
<style  src="@/main.scss" lang="scss"></style>
<style lang="scss" scoped >
@import "@/_responsive.scss";

.z-10 {
  z-index: 2 !important;
  position: relative;
}
.section-1-svg {
  path {
    transition: 3s cubic-bezier(0.4, 0, 0.2, 1);
    stroke-dasharray: 1;
    stroke-dashoffset: 2;
    animation: lineanimation 3s cubic-bezier(0.4, 0, 0.2, 1) alternate;
  }
  &:hover {
    path {
      stroke-dasharray: 1;
      stroke-dashoffset: 1;
    }
  }
}
.pagos {
  height: 200px;
  width: 100%;
  justify-content: space-between !important;
}
@keyframes lineanimation {
  0% {
    stroke-dasharray: 1;
    stroke-dashoffset: 1;
  }

  50% {
    stroke-dasharray: 1;
    stroke-dashoffset: 2;
  }
  100% {
    stroke-dasharray: 1;
    stroke-dashoffset: 3;
  }
}
.section-1-svg {
  position: absolute;
  left: -30px;
  top: -40px;
  right: -38px;

  width: 250px;
}
.productoss {
  margin-bottom: 100px;
  h1 {
    color: #ff5900;
    font-size: 40px;
  }
}
.titulo-ocho {
  font-size: 3rem;
}

.wrap-product {
  width: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: #f2f2f2 solid 1px;
  transition: 0.3s ease-in-out;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  // box-shadow: #80808014 0px 10px 20px;
  &:hover {
    box-shadow: #77777716 0px 10px 20px;
    transform: translateY(-5px);
  }
  .wra-img {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .back {
      width: 60%;
      opacity: 0.5;
      position: absolute;
      z-index: 1;
    }
  }
  a {
    color: #ff5900 !important;
    margin-top: 30px;
    margin-bottom: 30px;
    text-decoration: none;
    &:hover {
      color: #ff9359 !important;
    }
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.texto-home {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
}
.flex-end {
  width: 100%;

  display: flex;
  justify-items: flex-start;
  align-items: flex-start;

  flex-direction: column;
}

.flex-end-home {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 3;
}
.fondo {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 0 !important;
}
@include responsive(mobile) {
  .flex-mobile-home {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    justify-content: center;
    padding-bottom: 50px;
    height: auto !important;
  }
}
.fondog {
  position: absolute;
  width: 100%;
  height: auto;
}

.home {
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: white;
  // background-image: url("../assets/fondo-new-01.jpg");
  background-size: cover;
  @include responsive(mobile) {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    // background-image: url("../assets/fondo-new-mobile-02.jpg");
    background-size: contain;
    padding-top: 10px;
  }
  @include responsive(desktop) {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.text-ordenalo {
  text-align: center;
  a {
    cursor: pointer;
    color: #ff5900 !important;
    border-bottom: 2px #ff5900 solid;
  }
  @include responsive(mobile) {
    margin-bottom: 20px;
  }
}
.btn-download {
  @include responsive(mobile) {
    margin-top: 20px;
  }
}
.img-mobile {
  display: none;
  width: 100%;
  @include responsive(mobile) {
    display: block;
  }
}
.phones-desk {
  @include responsive(mobile) {
    display: none;
  }
}
.texto {
  width: 700px;
  height: 180px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  left: 200px;
  top: 35vh;
  h1 {
    font-size: 40px !important;
    b {
      color: #ff5900;
    }
  }
  @include responsive(desktop) {
    width: 40%;
    height: auto;
    position: absolute;

    text-align: center;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    left: 10%;
    top: 35vh;
    p {
      text-align: justify;
    }
    h1 {
      font-size: 29px !important;
      line-height: 29px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  @include responsive(mobile) {
    width: 90%;
    height: auto;
    position: relative;
    height: auto;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    left: 0px;
    top: 0px;
    h1 {
      font-size: 29px !important;
      line-height: 29px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  @include responsive(small) {
    width: 100%;
    height: auto;
    position: relative;
    height: auto;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 300px;
    padding-left: 100px;
    padding-right: 100px;
    left: 0px;
    top: 0px;
    h1 {
      font-size: 29px !important;
      line-height: 29px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .sitemas {
    width: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 30px !important;
    }
  }
  h1 {
    font-family: "poppins-bold";
    font-size: 30px;
  }
}
.transp {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 1000;
  background: transparent;
}

.Iam {
  display: flex;

  height: 70px;
  margin-top: 0px;
  font: normal 40px/50px Montserrat, sans-serif;
}
.Iams {
  display: flex;

  height: 70px;
  margin-bottom: 20px;
  margin-top: 0px;
  font: normal 40px/50px Montserrat, sans-serif;
}
.Iam h1 {
  height: 70px;
  float: left;
  margin-right: 0.3em;
}
.Iams h1 {
  height: 70px;
  float: left;
  margin-right: 0.3em;
}
.Iam b {
  float: right;
  overflow: hidden;
  position: relative;
  height: 50px;
  top: 0px;
  left: 10px;
}
.Iams b {
  float: right;
  overflow: hidden;
  position: relative;
  height: 33px;
  top: 0px;
  line-height: 33px;
  left: 10px;
}
.Iams .innerIam {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  top: 0;
  left: 0;

  /*animation*/
  -webkit-animation: moves 5s;
  -moz-animation: moves 5s;
  -ms-animation: moves 5s;
  -o-animation: moves 5s;
  animation: moves 5s;
  /*animation-iteration-count*/
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  /*animation-delay*/
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -ms-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
}
.Iam .innerIam {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  top: 0;
  left: 0;

  /*animation*/
  -webkit-animation: move 5s;
  -moz-animation: move 5s;
  -ms-animation: move 5s;
  -o-animation: move 5s;
  animation: move 5s;
  /*animation-iteration-count*/
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  /*animation-delay*/
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -ms-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
}
.innerIam {
  color: #ff5900;
}
@keyframes moves {
  0% {
    top: 0px;
  }
  20% {
    top: -33px;
  }
  40% {
    top: -66px;
  }
  60% {
    top: -99px;
  }
  80% {
    top: -0px;
  }
}
@keyframes move {
  0% {
    top: 0px;
  }
  20% {
    top: -50px;
  }
  40% {
    top: -100px;
  }
  60% {
    top: -150px;
  }
  80% {
    top: -200px;
  }
}

@-webkit-keyframes move {
  0% {
    top: 0px;
  }
  20% {
    top: -50px;
  }
  40% {
    top: -100px;
  }
  60% {
    top: -150px;
  }
  80% {
    top: -200px;
  }
}
@-moz-keyframes move {
  0% {
    top: 0px;
  }
  20% {
    top: -50px;
  }
  40% {
    top: -100px;
  }
  60% {
    top: -150px;
  }
  80% {
    top: -200px;
  }
}
@-o-keyframes move {
  0% {
    top: 0px;
  }
  20% {
    top: -50px;
  }
  40% {
    top: -100px;
  }
  60% {
    top: -150px;
  }
  80% {
    top: -200px;
  }
}
@keyframes move {
  0% {
    top: 0px;
  }
  20% {
    top: -50px;
  }
  40% {
    top: -100px;
  }
  60% {
    top: -150px;
  }
  80% {
    top: -200px;
  }
}
.facil-style {
  background-color: #ff5900e0;
  color: #fff;
  padding: 3px 10px;
  border-radius: 20px;
}
</style>
