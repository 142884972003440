<template >
  <v-container class="wrap-phone-animations">
    <div class="phone-App-animations">
      <v-row>
        <v-col class="text-logo" cols="12" sm="12" md="12" lg="12">
          <img src="@/assets/logo-naranja.svg" width="150px" class="mb-2" />

          <h2 class="mb-2">
            Somos el control <b class="color-primary">remoto</b> de tu ciudad
          </h2>
          <!-- <p class="text-ordenalo">{{ $t("text-ordenalo") }}</p> -->
          <p class="text-ordenalo">
            <b class="color-primary">Descarga</b> ocho y disfruta al instante de
            tus restaurantes favoritos,<br />
            bebidas, medicinas, envíos, supermercados y
            <b class="color-primary">mucho más</b>.
          </p>
        </v-col>
      </v-row>

      <img src="@/assets/phone2/1.jpeg" class="phone uno" />
      <img src="@/assets/phone2/2.jpeg" class="phone dos" />
      <img src="@/assets/phone2/Principal2.png" class="principal" />
      <img src="@/assets/phone2/3.jpeg" class="phone tres" />
      <img src="@/assets/phone2/4.jpeg" class="phone cuatro" />

      <img src="@/assets/phone2/index.png" class="phone_mobile slide1" />
      <img src="@/assets/phone2/3.jpeg" class="phone_mobile slide2" />
      <img src="@/assets/phone2/4.jpeg" class="phone_mobile slide3" />
      <img src="@/assets/phone/marco.png" class="phone_mobile marco" alt="" />

      <!-- <div class="phones-wrapp" >
       
      </div> -->
    </div>
  </v-container>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import store from "@/store/index.js";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: "Phone",
  props: {
    // className: String,
    // text: String,
  },

  data() {
    return {};
  },
  watch: {
    // cambioState(val) {
    //   this.$i18n.locale = val;
    //   this.text_btn = val;
    // },
  },
  methods: {
    startAnimations: function () {
      const scrollTrigger = {
        trigger: ".phone-App-animations",
        pin: true,
        pinSpacing: false,
        snap: { snapTo: [1], duration: 1, delay: 0 },
        markers: false,
        start: " top center",
        end: "bottom bottom",
        scrub: true,
      };

      console.debug(screen.width);

      if (screen.width <= 770) {
        gsap
          .timeline({ scrollTrigger })
          .from(".marco", { opacity: 1, duration: 2 })
          .from(".slide1", { opacity: 1, duration: 0.3 })
          .from(".slide2", { opacity: 0, duration: 0.3 })
          .from(".slide3", { opacity: 0, duration: 2 });
      } else if (screen.width >= 771) {
        gsap
          .timeline({ scrollTrigger })
          .to(".tres", { x: "-100%", ease: "inOut", opacity: 1, duration: 2 })
          .to(".dos", { x: "100%", ease: "inOut", opacity: 1, duration: 2 })
          .to(".cuatro", { x: "-200%", ease: "inOut", opacity: 1, duration: 3 })
          .to(".uno", { x: "200%", ease: "inOut", opacity: 1, duration: 3 });
      }

      // .from(".cuatro", { x: -470, ease: "inOut", opacity: 1, duration: 3 });

      // if (screen.width <= 770) {
      //   gsap
      //     .timeline({ scrollTrigger })
      //     .from(".marco", { opacity: 1, duration: 2 })
      //     .from(".slide1", { opacity: 1, duration: 0.3 })
      //     .from(".slide2", { opacity: 0, duration: 0.3 })
      //     .from(".slide3", { opacity: 0, duration: 2 });
      // } else if (screen.width <= 1025 && screen.width >= 1023) {
      //   gsap
      //     .timeline({ scrollTrigger })
      //     .from(".uno", { x: 270, ease: "inOut", opacity: 1, duration: 3 })
      //     .from(".dos", { x: 160, ease: "inOut", opacity: 1, duration: 2 })
      //     .from(".tres", { x: -160, ease: "inOut", opacity: 1, duration: 2 })
      //     .from(".cuatro", { x: -270, ease: "inOut", opacity: 1, duration: 3 });
      //   // .from(".cuatro", { x: -470, ease: "inOut", opacity: 1, duration: 3 });
      // } else if (screen.width > 1040) {
      //
    },
  },
  computed: {
    // cambioState() {
    //   return store.getters.stadocambio;
    // },
  },
  mounted: function () {
    this.startAnimations();
  },
};
</script>

<style lang="scss" scoped>
@import "@/_responsive.scss";
.color-primary {
  color: #ff5900;
}

.phone-App-animations {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  padding-top: 100px;
  height: 1200px;

  @include responsive(mobile) {
    text-align: center;
    padding-top: 0px;

    height: 2000px !important;
  }
}
.text-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
  @include responsive(mobile) {
    text-align: center;
  }
}

.phone {
  position: absolute;
  width: 17%;
  margin-top: 190px;
  border-radius: 1em;
  box-shadow: #2c455338 0px 10px 30px;
  @include responsive(mobile) {
    display: none;
  }
}

.wrap-phone-animations {
  height: auto;
  z-index: 0 !important;
}

.principal {
  z-index: 4;
  margin-top: 180px;
  position: absolute;
  width: 27% !important;
  @include responsive(mobile) {
    display: none;
  }
}
.uno,
.cuatro {
  z-index: 1;
  width: 16%;
  margin-top: 200px;
}
.dos,
.tres {
  z-index: 2;
}
.dp-none {
  @include responsive(mobile) {
    display: none;
  }
}
.phone_mobile {
  display: none !important;
}

@include responsive(mobile) {
  .phone_mobile {
    width: 85%;
    position: absolute;
    margin-top: 246px !important;
    display: block !important;
  }

  .slide1,
  .slide2,
  .slide3 {
    width: 58%;

    margin-top: 254px !important;
  }
}
</style>