<template>
  <transition name="fade">
    <div v-if="show" class="loader">
      <div class="lottie-content">
        <lottie :options="defaultOptions"  />
        <!-- v-on:animCreated="handleAnimation" -->
      </div>
    </div>
  </transition>
</template>
impor
<script>
import Lottie from "./Lottie";

import store from "@/store/index.js";
import * as animationData from "@/assets/lottie/data.json";
// import * as animationData from "@/assets/lottie/lf30_editor_vsmrycuz.json";
// import * as animationData from "@/assets/lottie/ocho-super-fast.json";
// import * as animationData from "@/assets/lottie/ocho-preloader-2.json";
export default {
  name: "Preloader",
  components: {
    lottie: Lottie,
  },
  props: {},
  data() {
    return {
      anim: null,
      animationSpeed: 2,
      defaultOptions: {
        animationData: animationData.default,
        loop: false,
        autoplay: true,
       lenguaje:""
      },
      show: true,
    };
  },
  mounted() {
    this.showLoader();
  },
  methods: {
    showLoader() {
      window.onload = setTimeout(() => {
    if (window.navigator.language === "es-419"|| window.navigator.language === "es" || window.navigator.language === "es-ES") {
      this.lenguaje="es"
      store.commit("ActualizarStado", this.lenguaje)
}else{
  this.lenguaje="en"
store.commit("ActualizarStado", this.lenguaje)

}
        this.show = false;
      }, 3000);
      console.log(window.navigator.language)
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
 $Naranja: #ff5900;
@import "@/_responsive.scss";

.loader {
  width: 100vw;
  height: 100vh;
  background-color: $Naranja;
  z-index: 1000 !important;
  display: flex;
//  display: none; 
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: hidden;
  position: fixed;
  flex-direction: column;
  .lottie-content {
    width: 300px;
    height: 300px;
  }
}
@include responsive(mobile) {
  .lottie-content {
    width: 220px !important;
    height: 100px;
  }
}
@include responsive(desktop) {
  .lottie-content {
    width: 220px !important;
    height: 100px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
